<template>
    <div class="card-tail card-f-h">
        <ClientsTable :clients="clients" />
    </div>
</template>

<script>
import Api from "./openbanking.api.js";
import ClientsTable from "./ui/ClientsTable.vue";

export default {
    components: {
        ClientsTable,
    },

    data: function() {
        return {
            clients: [],
        };
    },

    watch: {
        async isGlobalClientSelected() {
            this.getList();
        },
    },

    beforeCreate() {
        this.$store.commit("setTopbarTitle", "Open Banking");
    },

    async created() {
        this.getList();
        document.title = "Bank - Status";
    },

    methods: {
        async getList() {
            this.$waiting.start("loading");
            if (this.isGlobalClientSelected) {
                this.clients = await Api.getClientBankStatement(this.$store.state.topBar.selectedClient.value);
            } else {
                this.clients = await Api.getClients();
            }
            this.$waiting.end("loading");
        },
    },

    computed: {
        isGlobalClientSelected() {
            return this.$store.getters["topBar/isClientSelected"];
        },
    },
};
</script>
