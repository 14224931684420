import Vue from "vue";

export default {
    getClients: async () => {
        const response = await Vue.axios.get(`openbanking/getClients`);
        return response.data;
    },

    getClientBankStatement: async clientId => {
        const response = await Vue.axios.get(`openbanking/bankStatement/${clientId}`);
        return response.data;
    },
};
