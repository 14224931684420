<template>
    <el-table :data="clients" v-loading="$waiting.is('loading')" class="w-full">
        <el-table-column prop="number" label="Nr" width="90" sortable />
        <el-table-column prop="name" label="Företag" sortable :sort-method="sortingMethodCompany" />
        <el-table-column prop="user" label="Person" sortable :sort-method="sortingMethodPerson" />
        <el-table-column prop="providerId" label="Bank" sortable :sort-method="sortingMethodBank" />
        <el-table-column prop="unattended" label="Obevakad" />
        <el-table-column prop="hours" label="Timmar" align="right" sortable />
        <el-table-column prop="consentDate" label="Medgivande" align="right" />
        <el-table-column prop="created" label="Skapad" align="right" />
    </el-table>
</template>

<script>
export default {
    props: {
        clients: {
            type: Array,
            default: () => [],
        },
    },

    methods: {
        sortingMethodCompany(a, b) {
            return a.name.trim().toLowerCase() > b.name.trim().toLowerCase() ? 1 : -1;
        },

        sortingMethodPerson(a, b) {
            return a.user.trim().toLowerCase() > b.user.trim().toLowerCase() ? 1 : -1;
        },

        // Stackoverflow solution for solving sorting with not existing values for some objects
        sortingMethodBank(a, b) {
            if (a.providerId === "" || a.providerId === null) return 1;
            if (b.providerId === "" || b.providerId === null) return -1;
            if (a.providerId === b.providerId) return 0;
            return a.providerId < b.providerId ? -1 : 1;
        },
    },
};
</script>
